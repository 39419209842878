<template>
  <div :class="{ 'mt-6': !first }" :data-testid="dataTestid">
    <label
      :for="id"
      class="block leading-6 text-base font-medium text-ol-dark-200"
      >{{ label }}</label
    >
    <div class="relative mt-2">
      <input
        :type="type"
        :name="name"
        :id="id"
        :placeholder="placeholder"
        :value="modelValue"
        :required="required"
        :min="min"
        :max="max"
        @input="handleInput"
        @focus="$emit('focus')"
        :aria-invalid="has_error"
        :aria-describedby="name"
        class="rounded-[10px] block w-full border border-ol-plum py-1.5 pl-4 text-gray-900 outline-none placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ol-purple text-sm leading-8 lg:leading-12 bg-ol-cream"
        :class="{
          'text-red-200 ring-red-100 ring-2 placeholder:text-red-100':
            has_error,
        }"
      />
      <div
        v-if="has_error"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <svg
          class="h-5 w-5 text-red-100"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <p v-if="has_error" class="mt-2 text-sm text-red-200">
      {{ error_message }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    dataTestid: {
      type: String,
      default: 'text-input',
      required: false,
    },

    id: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      default: 'text',
      required: false,
    },

    placeholder: {
      type: String,
      required: false,
    },

    label: {
      type: String,
      required: false,
    },

    required: {
      type: Boolean,
      default: false,
      required: false,
    },

    has_error: {
      type: Boolean,
      default: false,
      required: false,
    },

    error_message: {
      type: String,
      default: '',
      required: false,
    },

    min: {
      type: String,
      required: false,
    },

    max: {
      type: String,
      required: false,
    },

    modelValue: {
      type: String,
    },

    first: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    handleInput($event) {
      this.$emit('update:modelValue', $event.target.value);
    },
  },
};
</script>

<style scoped>
input::placeholder {
  @apply text-ol-dark-20;
}

input[type='date'] {
  -webkit-appearance: none;
  height: 64px;
}
</style>
