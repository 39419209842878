<template>
  <div class="container" :class="gridClass">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Container',

  props: {
    isGrid: {
      type: Boolean,
      default: true,
      required: false,
    },
  },

  computed: {
    gridClass() {
      return this.isGrid
        ? 'grid grid-cols-8 lg:grid-cols-12 gap-x-4 lg:gap-x-12 xl:gap-x-24'
        : 'flex';
    },
  },
};
</script>
