import zxcvbn from 'zxcvbn';

export function isInDateFormat(d) {
  return d.match(
    /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])/
  );
}

export function isOverEighteen(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age >= 18;
}

const passwordLength = (value) => value.length > 7;
const containsCapital = (value) => /[A-Z]/.test(value);
const containsNumber = (value) => /[0-9]/.test(value);
const containsSpecialCharacter = (value) => /[!\-@$%&*]/.test(value);

const notCommon = (value) => zxcvbn(value).score >= 2;

export {
  passwordLength,
  containsNumber,
  containsCapital,
  containsSpecialCharacter,
  notCommon,
};
