<template>
  <div class="h-screen bg-ol-cream relative">
    <div
      :class="[
        enableKnock
          ? 'knock-top left-0 sticky pt-6'
          : 'w-full h-full flex justify-center items-center',
        { 'absolute top-0 left-0 z-10': image },
      ]"
    >
      <h3
        class="font-ol-roobert text-ol-heading-3 lg:text-ol-heading-1 font-medium text-center py-6"
      >
        <slot />
      </h3>
    </div>
    <picture v-if="image">
      <source :srcset="image.srcset" type="image/jpg" />
      <img
        class="object-cover w-full h-full absolute top-0 left-0"
        :src="image.src"
        alt="Placeholder3000x2000"
        loading="lazy"
      />
    </picture>
  </div>
</template>

<script>
export default {
  name: 'KnockOnText',

  props: {
    image: {
      type: Object,
      default: () => {},
      required: false,
    },

    enableKnock: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
</script>

<style scoped>
.knock-top {
  top: calc(50% - (var(--header-height) / 2));
}
</style>
