<template>
  <svg viewBox="0 0 300 150" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50z"
      fill="none"
      :stroke="colour"
      stroke-dasharray="300 385"
      stroke-linecap="round"
      stroke-width="15"
    >
      <animate
        attributeName="stroke-dashoffset"
        calcMode="spline"
        dur="2.15"
        keySplines="0 0 1 1"
        repeatCount="indefinite"
        values="685;-685"
      />
    </path>
  </svg>
</template>

<script>
export default {
  props: {
    colour: {
      type: String,
      default: 'white',
    },
  },
};
</script>
