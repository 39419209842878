<template>
  <button
    id="button"
    :data-testid="dataTestid"
    class="disabled:opacity-40 disabled:pointer-events-none flex justify-center"
    :class="[buttonSize, buttonClass]"
    :disabled="isDisabled"
  >
    <LoadingSpinner
      v-if="saving"
      key="saving"
      :class="[spinnerWidth, spinnerHeight]"
      class="scale-175"
    />
    <slot v-else>Save</slot>
  </button>
</template>

<script>
import { computed } from 'vue';
import LoadingSpinner from '@components/Icon/LoadingSpinner.vue';

export default {
  name: 'OLButton',
  components: { LoadingSpinner },
  props: {
    colour: {
      type: String,
      default: 'default',
      validator: function (value) {
        return ['default', 'cream', 'special'].includes(value);
      },
    },
    size: {
      type: String,
      default: 'medium',
      validator: function (value) {
        return ['small', 'medium', 'large'].includes(value);
      },
    },

    variant: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'secondary', 'text', 'circle'].includes(value);
      },
    },
    saving: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    dataTestid: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const buttonSize = computed(() => {
      if (props.size === 'xs') {
        return 'xs';
      }
      return 'small';
    });

    const spinnerWidth = computed(() => {
      return `w-8`;
    });
    const spinnerHeight = computed(() => {
      return `h-5`;
    });

    const buttonClass = computed(() => {
      return `${props.variant}__${props.colour}`;
    });

    return {
      spinnerHeight,
      buttonSize,
      spinnerWidth,
      buttonClass,
    };
  },
};
</script>

<style scoped>
.small {
  @apply py-3 px-8 text-sm;
}

.secondary__cream {
  @apply bg-ol-cream text-ol-dark-ink-200 hover:bg-transparent
  hover:text-ol-dark-ink-200 border-2 border-ol-dark-ink-200 rounded-full;
}

.primary__cream {
  @apply bg-ol-cream text-ol-primary
  border-2 border-ol-primary rounded-full;
}

.primary__special {
  @apply text-center bg-ol-dark-ink-200
  text-white hover:bg-ol-accent-1-100  transition-colors
  duration-150 ease-in-out  border border-white hover:border-ol-accent-1-100
  whitespace-nowrap  items-center justify-center rounded-full;
}

.primary__default {
  @apply text-center bg-ol-primary
  text-white transition-colors
  duration-150 ease-in-out  border border-ol-primary hover:bg-ol-dark-ink-200
  whitespace-nowrap  items-center justify-center rounded-full;
}

.secondary__default {
  @apply text-center text-ol-primary border-2
  transition-colors bg-white border-ol-primary
  duration-150 ease-in-out  hover:bg-ol-primary-10
  whitespace-nowrap  items-center justify-center rounded-full disabled:opacity-40 disabled:pointer-events-none;
}
</style>
