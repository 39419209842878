import { computed } from 'vue';
import {
  containsCapital,
  containsNumber,
  containsSpecialCharacter,
  notCommon,
  passwordLength,
} from '@js/utils/vanillaValidations';

export function usePasswordRequirements(password) {
  const requirements = computed(() => [
    {
      id: 'characterLength',
      name: 'Must be 8 character minimum',
      met: passwordLength(password.value),
    },
    {
      id: 'includeCapitalLetter',
      name: 'Must include a capital',
      met: containsCapital(password.value),
    },
    {
      id: 'includeNumber',
      name: 'Must include a number',
      met: containsNumber(password.value),
    },
    {
      id: 'includeSpecial',
      name: 'Must include a special character (!-@$%&*)',
      met: containsSpecialCharacter(password.value),
    },
    {
      id: 'notCommon',
      name: 'Must not be a common password',
      met: notCommon(password.value),
    },
  ]);

  const requirementsMet = computed(() =>
    requirements.value.every((r) => r.met === true)
  );

  return { requirements, requirementsMet };
}
