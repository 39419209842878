<template>
  <button
    class="flex justify-center gap-1.5 items-baseline button--label text-black rounded-ol-cta py-4 align-middle my-auto px-8 font-ol-roobert text-sm gap-x-2 lg:text-base fill-black"
    :class="[classBackground]"
  >
    <slot />
    <Arrow class="my-auto" />
  </button>
</template>

<script>
import Arrow from '../Icon/Arrow.vue';

export default {
  name: 'Cta',

  components: {
    Arrow,
  },

  props: {
    fill: {
      type: String,
      default: 'pink',
    },
    useNew: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    classBackground(props) {
      switch (this.fill) {
        case 'pink':
          return `${
            props.useNew
              ? 'bg-ol-dark-200 text-white w-full md:w-fit'
              : 'bg-ol-pink text-ol-dark-ink-200'
          } hover:bg-ol-accent-1-100 hover:text-white`;

        case 'brown':
          return 'bg-ol-gold text-ol-black';

        case 'gold':
          return 'bg-ol-gold text-ol-black';

        case 'plum':
          return 'bg-ol-plum text-white';

        case 'transparent':
          return 'bg-transparent border border-2 border-ol-pink text-ol-pink ';

        case 'transparent-blue':
          return 'bg-transparent border border-2 border-ol-dark text-ol-dark';

        default:
          return `${
            props.useNew
              ? 'bg-ol-dark-200 text-white w-full md:w-fit'
              : 'bg-ol-pink text-ol-dark-ink-200'
          } hover:bg-ol-accent-1-100 hover:text-white hover:border-ol-accent-100 `;
      }
    },
  },
};
</script>

<style scoped>
.button--label,
.button--arrow {
  box-shadow:
    0 0 8px 0 rgba(255, 254, 243, 0),
    0 0 6px 0 rgba(255, 255, 255, 0) inset,
    0 0 12px 0 rgba(255, 255, 255, 0) inset;

  transition-property: all;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
}
</style>
