<template>
  <svg
    fill="none"
    viewBox="0 0 26 26"
    width="26"
    height="26"
    xmlns="http://www.w3.org/2000/svg"
    :class="[isDisabled ? 'fill-ol-dark-20' : 'fill-ol-dark']"
  >
    <path
      d="m0.8125 13c0-6.731 5.4565-12.188 12.188-12.188 6.731 0 12.188 5.4565 12.188 12.188 0 6.731-5.4565 12.188-12.188 12.188-6.731 0-12.188-5.4565-12.188-12.188zm16.7-2.2676c0.3009-0.4213 0.2033-1.0068-0.218-1.3078-0.4213-0.30095-1.0068-0.20337-1.3078 0.21796l-4.0442 5.6619-2.03-2.0299c-0.36611-0.3661-0.95971-0.3661-1.3258 0-0.36612 0.3661-0.36612 0.9597 0 1.3258l2.8125 2.8125c0.1949 0.1949 0.4656 0.2941 0.7402 0.2714 0.2747-0.0227 0.5254-0.1651 0.6856-0.3894l4.6875-6.5625z"
      clip-rule="evenodd"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
